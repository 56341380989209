import '../App.css';
import { Form, Button, Input, message, Col, Row, theme, Card, Select, Space } from 'antd';
import React, { useState } from "react";
import axios from "axios";
import { CONN_KEY } from "../Conn";
const { Option } = Select;

function SellingItem() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    
    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = async (values) => {
        try {
            console.log('Received values of form: ', values);

            // Check if the barcode already exists
            const barcodeResponse = await axios.get(`${CONN_KEY}Add.php?barkode=${values.barkode}`);
            if (barcodeResponse.data.status === 1) {
                messageApi.open({
                    type: 'error',
                    content: 'Bu məhsuldan artığ yükləmisiniz. Stoku yeniləmək üçün Stoku yenile Səhifəsinə kecid edin!',
                });
                return;
            }

            // Barcode is unique, proceed with form submission
            const response = await axios.post(`${CONN_KEY}Add.php`, values);
            if (response.data.status === 1) {
                messageApi.open({
                    type: 'success',
                    content: 'Məhsul uğurla əlavə edildi',
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Bu məhsuldan artığ yükləmisiniz. Stoku yeniləmək üçün Stoku yenile Səhifəsinə kecid edin!',
                });
            }
        } catch (error) {
            console.log(error);
            alert('An error occurred. Please try again later.');
        }
    };

    const onFinishFailed = () => {
        message.error('Müvafiq sahələr boş qala bilməz!');
    };

    return (
        <div className="container ">{contextHolder}
            <h1>Yeni Məhsul əlavə et</h1>
            <Form
                onFinish={onFinish}
                className="login-form container"
                initialValues={{
                    remember: true,
                }} style={{
                    background: colorBgContainer,
                }}
                encType="multipart/form-data"
                layout="vertical"
                onFinishFailed={onFinishFailed}
            >
                <Card>
                    <div style={{ gap: "10" }}>
                        <Row gutter={[16, 16]}>
                            {/* Bar Code */}
                            <Col span={8}>
                                <Form.Item
                                    label="Bar Kod"
                                    name='barkode'
                                    rules={[{ required: true, message: 'Bar kod daxil edin!' }]}
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        name='barkode'
                                        onChange={handleChange}
                                        placeholder="4760081500020"
                                    />
                                </Form.Item>
                            </Col>
                            {/* Product Name */}
                            <Col span={8}>
                                <Form.Item
                                    label="Məhsulun adı"
                                    name="names"
                                    rules={[{ required: true, message: 'Məhsulun adı daxil edin!' }]}
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        name="names"
                                        size="large"
                                        onChange={handleChange}
                                        placeholder="Milla"
                                    />
                                </Form.Item>
                            </Col>
                            {/* Size */}
                            <Col span={8}>
                                <Space direction="horizontal">
                                    <Form.Item
                                        label="Məhsulun ölcüsü"
                                        name="size"
                                        rules={[{ required: true, message: 'Məhsulun ölçüsünü daxil edin!' }]}
                                    >
                                        <Input
                                            style={{
                                                width: '100%',
                                            }}
                                            name="size"
                                            size="large"
                                            onChange={handleChange}
                                            placeholder="1 Kq"
                                        />
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            {/* Purchase Price */}
                            <Col span={8}>
                                <Form.Item
                                    label="Məhsulun alış qiyməti"
                                    name='price'
                                    rules={[{ required: true, message: 'Alış qiyməti daxil edin!' }]}
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        name='price'
                                        onChange={handleChange}
                                        placeholder="500 AZN"
                                    />
                                </Form.Item>
                            </Col>
                            {/* Sell Price */}
                            <Col span={8}>
                                <Form.Item
                                    label="Məhsulun satış qiyməti"
                                    name='SelPrice'
                                    rules={[{ required: true, message: 'Satış qiyməti daxil edin!' }]}
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        name='SelPrice'
                                        onChange={handleChange}
                                        placeholder="600 AZN"
                                    />
                                </Form.Item>
                            </Col>
                            {/* Unit */}
                            <Col span={8}>
                                <Form.Item
                                    label="Məhsulun vahidi"
                                    name="unit"
                                    rules={[{ required: true, message: 'Məhsulun vahidini seçin!' }]}
                                >
                                    <Select
                                        placeholder="Seçin"
                                        size="large"
                                        onChange={(value) => handleChange({ target: { name: 'unit', value } })}
                                    >
                                        <Option value="pcs">Ədəd</Option>
                                        <Option value="liter">Litr</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            {/* Additional Info */}
                            <Col span={8}>
                                <Form.Item
                                    label="Əlavə məlumat"
                                    name="description"
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        name="description"
                                        size="large"
                                        onChange={handleChange}
                                        placeholder="Əlavə məlumat"
                                    />
                                </Form.Item>
                            </Col>
                            {/* Quantity */}
                            <Col span={8}>
                                <Form.Item
                                    label="Məhsulun sayı"
                                    name="miqdar"
                                    rules={[{ required: true, message: 'Məhsulun sayını daxil edin!' }]}
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        name="miqdar"
                                        size="large"
                                        onChange={handleChange}
                                        placeholder="Ədəd"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <Button htmlType="submit" type="primary" className='ColorMain'>
                        Əlavə et
                    </Button>
                </Card>
            </Form>
        </div>
    );
}

export default SellingItem;
