import { Table, Button, Space, Empty, Tooltip, Input } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { FileProtectOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import "../App.css";
import { CONN_KEY } from "../Conn";
import { Link } from 'react-router-dom';

const Sifarish = () => {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, dataExtractor) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button type="link" size="small" onClick={() => close()}>
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            const text = dataExtractor ? dataExtractor(record) : record[dataIndex];
            return text ? text.toString().toLowerCase().includes(value.toLowerCase()) : '';
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) => {
            const highlightedText = dataExtractor ? dataExtractor(record) : text;
            return searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={highlightedText ? highlightedText.toString() : ''}
                />
            ) : (
                highlightedText
            );
        },
    });
    

    useEffect(() => {
        axios.get(`${CONN_KEY}GetOrder.php`)
            .then(response => {
                setData(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const calculateTotal = (products) => {
        return products.reduce((total, product) => {
            const price = parseFloat(product.SelPrice);
            const discount = parseFloat(product.discount || 0);
            const quantity = parseFloat(product.miqdar || 1);
            return total + (price - discount) * quantity;
        }, 0).toFixed(2);
    };

    const columns = [
        {
            title: 'Sipariş №',
            dataIndex: 'sifarishNo',
            key: 'sifarishNo',
            ...getColumnSearchProps('sifarishNo'),
        },
        {
            title: 'Məhsul sayı',
            key: 'urunSayisi',
            render: (_, record) => (
                <span>{record.products ? record.products.length : 0}</span>
            ),
        },
        {
            title: 'Müştəri',
            key: 'CustomerName',
            render: (_, record) => (
                <Tooltip title={`${record.customer?.CustomerName || 'N/A'}, ${record.customer?.phone || 'N/A'}`}>
                    <span>{record.customer?.CustomerName || 'N/A'}</span>
                </Tooltip>
            ),
            ...getColumnSearchProps('CustomerName', record => record.customer?.CustomerName), // Extract nested customer name
        },
        {
            title: 'N/V',
            key: 'carSignInNumber',
            render: (_, record) => (
                <Tooltip title={`${record.car?.carSignInNumber || 'N/A'}, ${record.car?.brand || ''} ${record.car?.model || ''}`}>
                    <span>{record.car?.carSignInNumber || 'N/A'}</span>
                </Tooltip>
            ),
            ...getColumnSearchProps('carSignInNumber', record => record.car?.carSignInNumber), // Extract nested car sign number
        },
        {
            title: 'Tarix',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
            ...getColumnSearchProps('created_at'), // Apply search on date
        },
        {
            title: 'Toplam',
            key: 'total',
            render: (_, record) => (
                <span>₼{calculateTotal(record.products)}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'sifarishNo',
            key: 'action',
            render: (_, record) => (
                <Space>
                    <Link to={`/Faktura/${record.sifarishNo}`}>
                        <FileProtectOutlined /> Faktura
                    </Link>
                </Space>
            ),
        },
    ];

    return (
        <>
            {data.length > 0 ? (
                <Table
                    pagination={{ pageSize: 50 }}
                    columns={columns}
                    dataSource={data}
                    rowKey="order_id"
                    locale={{
                        emptyText: <Empty description="Sipariş bulunamadı." />,
                    }}
                />
            ) : (
                <Empty />
            )}
        </>
    );
};

export default Sifarish;
