import { Layout, theme } from 'antd';
import Tables from '../components/table';
import Footers from '../components/Footer';
import Headers from '../components/Header';
import Siders from '../components/Sider';
import { useCookies } from 'react-cookie';

const { Content } = Layout;
const Table = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [cookies] = useCookies(['username']);

    if (!cookies.username) {
        window.location.href = '/Login';
    }

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout"
                    style={{
                        padding: '0 30px',
                        height: '80vh'
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            // minHeight: 380,
                            background: colorBgContainer,
                        }}
                    >
                        <h1 >Stokda olan mehsullar</h1>
                        <br /><br />
                        <Tables />
                    </div>
                </Content>
                <Footers />
            </Layout>
        </Layout>
    );
};
export default Table;