import { Layout, theme } from 'antd';
import Footers from '../components/Footer';
import Headers from '../components/Header';
import React from 'react';
import Siders from '../components/Sider';
import DemoColumn from '../components/ChartStatistic';
import CustumersList from '../components/CustomerList';
import App from '../components/Revenue';
import { useCookies } from 'react-cookie';
import Sifarish from '../components/SifarishMain';

const { Content } = Layout;

const Order = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [cookies] = useCookies(['username']);

  if (!cookies.username) {
    window.location.href = '/Login';
  }

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Siders />
      <Layout>
        <Headers />
        <Content
          className="site-layout"
          style={{
            padding: '0 25px',
          }}
        >
          <App />
          <br />
          <div
            style={{
              padding: 24,
              minHeight: 380,
              background: colorBgContainer,
            }}
          >
            <div style={{ display: "grid", gap: 16 }}>
              <div style={{ display: "flex", gap: 16 }}>
                <CustumersList />
                <Sifarish />
              </div>
              <DemoColumn />
            </div>
          </div>
        </Content>
        <Footers />
      </Layout>
    </Layout>
  );
};

export default Order;
