import { Layout, Menu, Image } from 'antd';
import { AppstoreAddOutlined, ToTopOutlined, SolutionOutlined, LineChartOutlined, AppstoreOutlined, CarOutlined, TagsOutlined, SettingOutlined, CalendarOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import Logo from '../logo.svg';

const { Sider } = Layout;
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}
const items = [
    {
        label: (
            <a href="/Main" rel="noopener noreferrer">
                Statistika
            </a>
        ),
        key: 'main',
        icon: <LineChartOutlined />
    },
    {
        type: 'divider',
    },
    {
        label: (
            <a href="/Tables" rel="noopener noreferrer">
                Məhsullar
            </a>
        ),
        key: 'Məhsullar',
        icon: <AppstoreOutlined />
    },
    {
        type: 'divider',
    },
    {
        label: (
            <a href="/add" rel="noopener noreferrer">
                Yeni məhsul Əlavə et
            </a>
        ),
        key: 'add',
        icon: <AppstoreAddOutlined />
    },
    {
        type: 'divider',
    },
    {
        label: (
            <a href="/Order" rel="noopener noreferrer">
                Sifariş ver
            </a>
        ),
        key: 'Order',
        icon: <ToTopOutlined />
    },
    {
        type: 'divider',
    },
    {
        label: (
            <a href="/Müşteriler" rel="noopener noreferrer">
                Müşteriler
            </a>
        ),
        key: 'Customers',
        icon: <SolutionOutlined />
    },
    {
        type: 'divider',
    },
    {
        label: (
            <a href="/orders" rel="noopener noreferrer">
                Sifarişlər
            </a>
        ),
        key: 'Orders',
        icon: <TagsOutlined />
    },
    {
        type: 'divider',
    },
    // {
    //     label: (
    //         <a href="/Kalendar" rel="noopener noreferrer">
    //             Kalendar
    //         </a>
    //     ),
    //     key: 'Kalendar',
    //     icon: <CalendarOutlined />
    // },
    {
        type: 'divider',
    },
    {
        label: (
            <a href="/Truck" rel="noopener noreferrer">
                Maşınlar
            </a>
        ),
        key: 'maşınları',
        icon: <CarOutlined />
    },
    {
        type: 'divider',
    },
    {
        label: (
            <a href="/Settings" rel="noopener noreferrer">
                Tənzimləmələr
            </a>
        ),
        key: 'Settings',
        icon: <SettingOutlined />
    }
];

const Siders = () => {

    const [collapsed, setCollapsed] = useState(false);

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <Image
                style={{
                    height: 32,
                    margin: 16,
                }}
                preview={false}
                src={Logo}
            />
            <Menu
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                mode="inline"
                theme="dark"
                inlineCollapsed={collapsed}
                items={items}
            />
        </Sider >
    );
};
export default Siders;