import { Layout, theme } from 'antd';
import Footers from '../components/Footer';
import Headers from '../components/Header';
import Siders from '../components/Sider';
import CardCustomer from '../components/CustomersCard';
import { useCookies } from 'react-cookie';

const { Content } = Layout;

const Customer = () => {


    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [cookies] = useCookies(['username']);

    if (!cookies.username) {
      window.location.href = '/Login';
    }
  
    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout"
                    style={{
                        padding: '0 50px',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight: 380,
                            background: colorBgContainer,
                        }}
                    >
                        <CardCustomer />
                    </div>
                </Content>
                <Footers />
            </Layout>
        </Layout>
    );
};

export default Customer;
