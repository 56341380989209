import { Avatar, Card, Divider, List, Skeleton, Typography } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CONN_KEY } from "../Conn";

const { Text, Title } = Typography;

const App = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [colorMap, setColorMap] = useState({}); // Object to store customer colors

    const loadMoreData = () => {
        if (loading) return;
        setLoading(true);

        axios
            .get(`${CONN_KEY}GetOrder.php`)
            .then((res) => res.data.data)
            .then((body) => {
                // Filter out duplicates by checking if the sifarishNo already exists in the data state
                const newData = body.filter((item) => !data.some((existingItem) => existingItem.sifarishNo === item.sifarishNo));
                setData([...data, ...newData]);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadMoreData();
    }, []);

    const getLightColor = () => {
        const hue = Math.floor(Math.random() * 360);
        const saturation = Math.floor(Math.random() * 30) + 70; // Lighter saturation (70-100)
        const lightness = Math.floor(Math.random() * 30) + 40; // Lighter lightness (60-80)
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    const getAvatarColor = (customerName) => {
        if (colorMap[customerName]) {
            return colorMap[customerName];
        } else {
            const color = getLightColor();
            setColorMap((prevColorMap) => ({
                ...prevColorMap,
                [customerName]: color,
            }));
            return color;
        }
    };

    return (
        <Card
            title={<Title level={3}>Sifarişlər</Title>}
            id="scrollableDiv"
            style={{
                height: '500px',
                width: '100%',
                overflow: 'auto',
                padding: '16px',
                borderRadius: '10px',
                backgroundColor: '#f5f5f5',
            }}
        >
            <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={true}
                loader={<Skeleton avatar paragraph={{ rows: 3 }} active />}
                endMessage={<Divider plain>Bu qədər, başqa heç nə 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={data}
                    renderItem={(order) => (
                        <Card
                            key={order.sifarishNo}
                            bordered={false}
                            style={{
                                marginBottom: '20px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '10px',
                                padding: '16px',
                            }}
                        >
                            <Title level={5}>Sifariş No: {order.sifarishNo}</Title>
                            <Text type="secondary">Tarix: {order.created_at}</Text>

                            <Divider style={{ margin: '10px 0' }} />

                            <Title level={5}>Müştəri:</Title>
                            <div style={{ paddingLeft: '10px', marginBottom: '10px' }}>
                                <Text strong>Ad: </Text>{order.customer.CustomerName}<br />
                                <Text strong>Email: </Text>{order.customer.email}<br />
                                <Text strong>Telefon: </Text>{order.customer.phone}<br />
                                <Text strong>Ünvan: </Text>{order.customer.address}<br />
                            </div>

                            <Divider style={{ margin: '10px 0' }} />

                            {order.car && (
                                <>
                                    <Title level={5}>Avtomobil:</Title>
                                    <div style={{ paddingLeft: '10px', marginBottom: '10px' }}>
                                        <Text strong>Nömrə: </Text>{order.car.carSignInNumber}<br />
                                        <Text strong>Marka: </Text>{order.car.brand} {order.car.model}<br />
                                        <Text strong>Rəng: </Text>{order.car.color}<br />
                                    </div>
                                    <Divider style={{ margin: '10px 0' }} />
                                </>
                            )}

                            <Title level={5}>Məhsullar:</Title>
                            {order.products && order.products.map((product, index) => (
                                <div key={index} style={{ display: 'flex', marginBottom: '15px', alignItems: 'center' }}>
                                    <Avatar
                                        style={{
                                            backgroundColor: getAvatarColor(order.customer.CustomerName),
                                            color: '#ffffff',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {order.customer.CustomerName.charAt(0)}
                                    </Avatar>

                                    <div style={{ flex: 1 }}>
                                        <Title level={5} style={{ margin: 0 }}>{product.product_name}</Title>
                                        <Text>
                                            Miqdar: {product.miqdar} {product.unit === 'liter' ? 'lt' : 'əd'} | Qiymət: ₼{product.SelPrice} | Endirim: ₼{product.discount}
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </Card>
                    )}
                />
            </InfiniteScroll>
        </Card>
    );
};

export default App;
