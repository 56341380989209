import { useEffect, useState } from "react";
import { Table, Button, Input, Select, Form, Modal, Alert } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import axios from "axios";
import { CONN_KEY } from "../Conn";

const ProductSelection = ({ }) => {
  const [category, setCategory] = useState("Product");
  const [barkode, setBarkode] = useState("");
  const [Customer, setCustomer] = useState([]);
  const [Car, setCar] = useState([]);  // Initialize as an empty array
  const [selectedCar, setSelectedCar] = useState(null);  // Selected car state
  const [miqdar, setMiqdar] = useState("");
  const [SelPrice, setSelPrice] = useState("");  // Auto-filled unit price (Qiymət)
  const [discount, setDiscount] = useState(0);
  const [currentKm, setCurrentKm] = useState(""); // New state for currentKm
  const [needChangeKm, setNeedChangeKm] = useState(""); // New state for needChangeKm
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = (barkode) => {
    const filteredProducts = selectedProducts.filter(product => product.barkode !== barkode);
    setSelectedProducts(filteredProducts);
  };   

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Barkod",
      dataIndex: "barkode",
      key: "barkode",
      render: (text, record) => (
        <div>
          {text}
          <Button
            type="link"
            danger
            onClick={() => handleDelete(record.barkode)} // Pass barkode instead of key
          >
            Delete
          </Button>
        </div>
      )
    },    
    {
      title: "Qiymət",
      dataIndex: "SelPrice",
      key: "SelPrice",
      render: (text) => `${text} ₼`
    },
    {
      title: "Müştəri",
      dataIndex: "customer_id",
      key: "customer_id",
      render: (text) => Customer.find(c => c.id === text)?.CustomerName || text,
    },
    {
      title: "Miqdar (sayı)",
      dataIndex: "miqdar",
      key: "miqdar"
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text) => `${text} ₼`
    },
    {
      title: "Qiymət (with discount)",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (_, record) => {
        const discountedPrice = record.SelPrice - record.discount;
        return (discountedPrice * record.miqdar).toFixed(2) + " ₼";
      }
    },
    {
      title: "Current KM",
      dataIndex: "currentKm",
      key: "currentKm",
      render: (text, record) => record.category === "Car" ? text : null,
    },
    {
      title: "Need Change KM",
      dataIndex: "needChangeKm",
      key: "needChangeKm",
      render: (text, record) => record.category === "Car" ? text : null,
    },
    {
      title: "Car Sign-in Number",
      dataIndex: "carSignInNumber",
      key: "carSignInNumber",
      render: (text, record) => record.category === "Car" ? text : null,
    },
  ];

  const handleAdd = () => {
    const selectedProduct = products.find((product) => product.barkode === barkode);
    const productStock = parseFloat(selectedProduct?.Stokda || 0); // Convert stock to a float
    const requestedQuantity = parseFloat(miqdar); // Convert miqdar to a float
  
    if (selectedProduct && requestedQuantity <= productStock) {
      const newProduct = {
        category,
        barkode,
        miqdar: requestedQuantity,
        SelPrice: parseFloat(SelPrice).toFixed(2),
        discount,
        customer_id: selectedCustomer,
        car_id: selectedCar,
        currentKm: category === "Car" ? currentKm : null,
        needChangeKm: category === "Car" ? needChangeKm : null,
      };
  
      setSelectedProducts([...selectedProducts, newProduct]);
  
      // Reset fields except selectedCar
      setCategory("Product");
      setBarkode("");
      setMiqdar("");
      setSelPrice("");
      setDiscount(0);
      setCurrentKm("");
      setNeedChangeKm("");
      setErrorMessage("");
      setIsModalOpen(false);
    } else {
      setErrorMessage("Stokda yetərli məhsul yoxdur");
    }
  };

  const handleSend = () => {
    if (selectedProducts.length === 0) {
      setErrorMessage("No products selected!");
      return;
    }

    const sifarishNo = Math.floor(Math.random() * 10000);

    // Check payload for selectedCar
    console.log("Sending data:", {
      sifarishNo,
      customer_id: selectedCustomer,
      products: selectedProducts,
      car_id: selectedCar,
    });

    axios
      .post(`${CONN_KEY}Sifarish.php`, {
        sifarishNo,
        customer_id: selectedCustomer,
        products: selectedProducts,
        car_id: selectedCar  // Send car_id in payload
      })
      .then((response) => {
        console.log("Order Response:", response.data);
        setSelectedProducts([]);
      })
      .catch((error) => {
        console.error("Order Submission Error:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${CONN_KEY}Add.php`)
      .then((response) => {
        console.log("Products Data:", response.data);
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Products Fetch Error:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${CONN_KEY}Customer.php`)
      .then((response) => {
        setCustomer(response.data);
      })
      .catch((error) => {
        console.error("Customer Fetch Error:", error);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${CONN_KEY}Car.php`)
      .then((response) => {
        console.log("Car Response Data:", response.data); // Log the car response data for debugging
        if (Array.isArray(response.data)) {
          setCar(response.data); // Set car data from API
        } else {
          console.error("Invalid car data format:", response.data);
          setCar([]);
        }
      })
      .catch((error) => {
        console.error("Car Fetch Error:", error);
        setCar([]);
      });
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleBarkodeChange = (value) => {
    setBarkode(value);
    const selectedProduct = products.find((product) => product.barkode === value);
    if (selectedProduct) {
      const SelPrice = selectedProduct.SelPrice ? parseFloat(selectedProduct.SelPrice).toFixed(2) : "0.00";
      setSelPrice(SelPrice);
    }
  };

  return (
    <Form>
      <Modal title="Məhsul əlavə et" open={isModalOpen} onOk={handleAdd} onCancel={handleCancel}>
        {errorMessage && <Alert type="error" message={errorMessage} style={{ color: "red" }} />}
        <br />
        <Form.Item label="Category">
          <Select
            style={{ width: '100%' }}
            value={category}
            onChange={(value) => setCategory(value)}
          >
            <Select.Option value="Product">Product</Select.Option>
            <Select.Option value="Car">Car</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Müştəri">
          <Select
            style={{ width: '100%' }}
            placeholder="Müştəri"
            value={selectedCustomer}
            onChange={(value) => setSelectedCustomer(value)}
          >
            {Customer.map((customer) => (
              <Select.Option key={customer.id} value={customer.id}>
                {customer.CustomerName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Avtomobil">
          <Select
            style={{ width: '100%' }}
            placeholder="Avtomobil"
            value={selectedCar}
            onChange={(value) => setSelectedCar(value)}
          >
            {Car.map((car) => (
              <Select.Option key={car.id} value={car.id}>
                {car.carSignInNumber} - {car.brand} {car.model}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Barkod">
          <Select
            style={{ width: '100%' }}
            label="Barkod"
            placeholder="Barkod"
            value={barkode}
            onChange={handleBarkodeChange}
          >
            {products.map((product) => (
              <Select.Option key={product.id} value={product.barkode}>
                {product.names} ({product.barkode})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={`Miqdarı - ${products.find((product) => product.barkode === barkode)?.Stokda || 0}`}>
          <Input
            style={{ width: '100%' }}
            type="number"
            placeholder="Miqdar"
            value={miqdar}
            disabled={products.find((product) => product.barkode === barkode)?.Stokda === 0}
            onChange={(e) => setMiqdar(e.target.value)}
          />
          {products.find((product) => product.barkode === barkode)?.Stokda === 0 && (
            <span style={{ color: "red" }}>Stokda Məhsul yoxdur</span>
          )}
        </Form.Item>
        <Form.Item label="Qiymət">
          <Input
            style={{ width: '100%' }}
            type="number"
            placeholder="Qiymət"
            value={SelPrice}
            prefix="₼"
            readOnly
          />
        </Form.Item>
        <Form.Item label="Endirim (₼)">
          <Input
            style={{ width: '100%' }}
            type="number"
            placeholder="Endirim"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
          />
        </Form.Item>
        {/* Conditional fields for cars */}
        {category === "Car" && (
          <>
            <Form.Item label="Current KM">
              <Input
                style={{ width: '100%' }}
                type="number"
                placeholder="Current KM"
                value={currentKm}
                onChange={(e) => setCurrentKm(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Need Change KM">
              <Input
                style={{ width: '100%' }}
                type="number"
                placeholder="Need Change KM"
                value={needChangeKm}
                onChange={(e) => setNeedChangeKm(e.target.value)}
              />
            </Form.Item>
          </>
        )}
      </Modal>
      <Table
        dataSource={selectedProducts}
        columns={columns}
        rowKey={(record) => record.barkode}
        title={() => <Button type="default" icon={<PlusOutlined />} onClick={showModal}>Əlavə et</Button>}
        footer={() => <Button type="primary" onClick={handleSend}>Göndər</Button>}
      />
    </Form>
  );
};

export default ProductSelection;
