import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Modal, Table } from 'antd';
import axios from 'axios';
import { CONN_KEY } from "../Conn";

const AddCar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [carSignInNumber, setCarSignInNumber] = useState('');
    const [carList, setCarList] = useState([]); // State for car list
    const [form] = Form.useForm();

    // Fetch car data on component load
    useEffect(() => {
        fetchCarList();
    }, []);

    // Function to fetch car data from API
    const fetchCarList = () => {
        axios.get(`${CONN_KEY}Car.php`) // Replace with your actual endpoint
            .then(response => {
                setCarList(response.data); // Set the response data to carList state
            })
            .catch(error => {
                console.error("Error fetching car list:", error);
                message.error('Error fetching car list.');
            });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
        setCarSignInNumber('');
    };

    // Function to handle car number input with auto-formatting
    const handleCarSignInChange = (value) => {
        value = value.toUpperCase().replace(/[^0-9A-Z]/g, ''); // Allow only alphanumeric characters

        // Step 1: Get the first 2 numbers
        let firstPart = value.slice(0, 2).replace(/[^0-9]/g, ''); // Enforce only numbers in first part

        // Step 2: Get the middle 2 letters
        let secondPart = value.slice(2, 4).replace(/[^A-Z]/g, ''); // Enforce only letters in middle part

        // Step 3: Get the last 3 numbers
        let thirdPart = value.slice(4, 7).replace(/[^0-9]/g, ''); // Enforce only numbers in last part

        let formattedValue = firstPart;

        // Add hyphen after first part if the length is correct
        if (secondPart.length > 0) {
            formattedValue += '-' + secondPart;
        }

        // Add hyphen after second part if the length is correct
        if (thirdPart.length > 0) {
            formattedValue += '-' + thirdPart;
        }

        setCarSignInNumber(formattedValue);
    };

    const handleAddCar = (values) => {
        axios.post(`${CONN_KEY}Car.php`, values)
            .then(response => {
                if (response.data.status === 1) {
                    message.success('Car added successfully!');
                    setIsModalOpen(false);
                    form.resetFields();
                    setCarSignInNumber('');
                    fetchCarList(); // Refresh the car list after adding a new car
                } else {
                    message.error(response.data.message);
                }
            })
            .catch(error => {
                console.error("Error adding car:", error);
                message.error('Error adding car. Please try again.');
            });
    };

    // Table columns configuration
    const columns = [
        { title: 'Car Sign-In Number', dataIndex: 'carSignInNumber', key: 'carSignInNumber' },
        { title: 'Brand', dataIndex: 'brand', key: 'brand' },
        { title: 'Model', dataIndex: 'model', key: 'model' },
        { title: 'Color', dataIndex: 'color', key: 'color' },
        { title: 'Current KM', dataIndex: 'currentKm', key: 'currentKm' },
        { title: 'VIN', dataIndex: 'vin', key: 'vin' }
    ];

    return (
        <div>
            <Button type="primary" onClick={showModal}>
                Add Car
            </Button>

            <Modal
                title="Add New Car"
                open={isModalOpen}
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    onFinish={handleAddCar}
                    layout="vertical"
                    initialValues={{ carSignInNumber }}
                >
                    <Form.Item
                        label="Car Sign-In Number"
                        name="carSignInNumber"
                        rules={[
                            { required: true, message: 'Please enter the car sign-in number' },
                            { pattern: /^[0-9]{2}-[A-Z]{2}-[0-9]{3}$/, message: 'Format must be 07-HH-797' }
                        ]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="07-HH-797"
                            value={carSignInNumber}
                            onChange={(e) => handleCarSignInChange(e.target.value)} // Apply the function
                            maxLength={9} // Ensure the input doesn't exceed 9 characters
                        />
                    </Form.Item>

                    <Form.Item
                        label="Brand"
                        name="brand"
                        rules={[{ required: true, message: 'Please enter the car brand' }]}
                    >
                        <Input placeholder="Enter brand" />
                    </Form.Item>

                    <Form.Item
                        label="Model"
                        name="model"
                        rules={[{ required: true, message: 'Please enter the car model' }]}
                    >
                        <Input placeholder="Enter model" />
                    </Form.Item>

                    <Form.Item
                        label="Color"
                        name="color"
                        rules={[{ required: true, message: 'Please enter the car color' }]}
                    >
                        <Input placeholder="Enter color" />
                    </Form.Item>

                    <Form.Item
                        label="Current KM"
                        name="currentKm"
                        rules={[{ required: true, message: 'Please enter the current KM' }]}
                    >
                        <Input type="number" placeholder="Enter current KM" />
                    </Form.Item>

                    <Form.Item
                        label="VIN"
                        name="vin"
                        rules={[{ required: true, message: 'Please enter the VIN' }]}
                    >
                        <Input placeholder="Enter VIN" />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Display the car list */}
            <Table
                dataSource={carList}
                columns={columns}
                rowKey={(record) => record.carSignInNumber} // Ensure each row has a unique key
                style={{ marginTop: '20px' }}
            />
        </div>
    );
};

export default AddCar;
