import { Skeleton, Button, Space } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { PrinterOutlined } from '@ant-design/icons';
import { CONN_KEY } from "../Conn";

function FakturaC() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { sifarishNo } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fakturaUrl = `${CONN_KEY}Faktura.php?sifarishNo=${sifarishNo}`;
                const fakturaResponse = await axios.get(fakturaUrl);
                setData(fakturaResponse.data.data); // Adjust based on your API structure
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [sifarishNo]);

    // Calculate the total including the direct discount
    // Calculate the total discount
    const totalDiscount = data.products
        ? data.products.reduce((acc, curr) => acc + curr.discount * curr.miqdar, 0)
        : 0;

    // Calculate the total including the direct discount
    const total = data.products
        ? data.products.reduce((acc, curr) => {
            const discountedPrice = curr.SelPrice - curr.discount;
            return acc + (curr.miqdar * discountedPrice);
        }, 0)
        : 0;


    const handlePrint = () => {
        const invoiceSection = document.getElementById("invoice-section");
        if (invoiceSection) {
            const printContents = invoiceSection.innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            window.location.reload();
        }
    };

    return (
        <>
            {isLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Space style={{ marginTop: '20px' }}>
                        <Button className="ColorMain" type="default" icon={<PrinterOutlined />} onClick={handlePrint}>
                            Print
                        </Button>
                    </Space>
                    <div className="invoice-box" id="invoice-section" style={{ width: '100%', maxWidth: '900px', margin: 'auto', padding: '20px', color: '#555' }}>
                        <div className="invoice-info">
                            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: '5px' }}>
                                            <b>Hesab №:</b> {data.sifarishNo || 'N/A'} <br />
                                            <b>Giriş tarixi:</b> {new Date(data.order_date).toLocaleDateString()} {new Date(data.order_date).toLocaleTimeString()}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br />
                        <div className="invoice-info">
                            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '50%', padding: '5px' }}>
                                            <strong>Sifarişçi:</strong> <b>{data.customer?.CustomerName || 'Unknown'}</b> <br />
                                            <strong>Tel:</strong> {data.customer?.phone || 'N/A'} <br />
                                            <strong>Servisə daxil olma :</strong> {data.products?.find(p => p.currentKm)?.currentKm || 'N/A'} km<br />
                                            <strong>Dəyişiklimək lazımdır :</strong> {data.products?.find(p => p.needChangeKm)?.needChangeKm || 'N/A'}km
                                        </td>

                                        <td style={{ width: '50%', padding: '5px' }}>
                                            <strong>Avtomobil:</strong> <br />
                                            <b>A/m DQN:</b> {data.car?.carSignInNumber || 'N/A'} <br />
                                            <b>Marka:</b> {data.car?.brand || 'N/A'} <br />
                                            <b>Model:</b> {data.car?.model || 'N/A'} <br />
                                            <b>Rəng:</b> {data.car?.color || 'N/A'} <br />
                                            <b>Ban nömrəsi:</b> {data.car?.vin || 'N/A'}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <table className="bordered-table" style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                            <thead className="heading" style={{ background: '#eee', borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>
                                <tr>
                                    <th style={{ padding: '5px', border: '1px solid #ddd' }}>№</th>
                                    <th style={{ padding: '5px', border: '1px solid #ddd' }}>Malın kodu</th>
                                    <th style={{ padding: '5px', border: '1px solid #ddd' }}>Malın adı</th>
                                    <th style={{ padding: '5px', border: '1px solid #ddd' }}>Ölçü V</th>
                                    <th style={{ padding: '5px', border: '1px solid #ddd' }}>Qiymət</th>
                                    <th style={{ padding: '5px', border: '1px solid #ddd' }}>Endirim (%)</th>
                                    <th style={{ padding: '5px', border: '1px solid #ddd' }}>Məbləğ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.products && data.products.map((product, index) => {
                                    const discountedPrice = product.SelPrice - product.discount;
                                    const totalPrice = product.miqdar * discountedPrice;

                                    return (
                                        <tr key={index}>
                                            <td style={{ padding: '5px', border: '1px solid #ddd' }}>{index + 1}</td>
                                            <td style={{ padding: '5px', border: '1px solid #ddd' }}>{product.barkode}</td>
                                            <td style={{ padding: '5px', border: '1px solid #ddd' }}>{product.names}</td>
                                            {/* <td style={{ padding: '5px', border: '1px solid #ddd' }}>{product.miqdar}</td> */}
                                            <td style={{ padding: '5px', border: '1px solid #ddd' }}>
                                                {product.miqdar} {product.unit === 'liter' ? 'lt' : product.unit === 'pcs' ? 'əd' : ''}
                                            </td>
                                            <td style={{ padding: '5px', border: '1px solid #ddd' }}>{parseFloat(product.SelPrice).toFixed(2)} ₼</td>
                                            <td style={{ padding: '5px', border: '1px solid #ddd' }}>{parseFloat(product.discount).toFixed(2)} ₼</td>
                                            <td style={{ padding: '5px', border: '1px solid #ddd' }}>{totalPrice.toFixed(2)} ₼</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        <div className="signature" style={{ paddingTop: '20px' }}>
                            <div className="left" style={{ float: 'left', width: '50%' }}>
                                <strong>Təhvil Verdi:</strong> __________
                            </div>
                            <div className="right" style={{ float: 'right', width: '50%', textAlign: 'right' }}>
                                <strong>Təhvil Aldı:</strong> __________
                            </div>
                        </div>

                        <div className="clear" style={{ clear: 'both' }}></div>
                        <br />
                        <table className="total" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ paddingRight: '10px' }}><strong>Cəmi:</strong></td>
                                    <td>₼ {total.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td style={{ paddingRight: '10px' }}><strong>Ümumi E:</strong></td>
                                    <td>₼ {totalDiscount.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td style={{ paddingRight: '10px' }}><strong>Yekun:</strong></td>
                                    <td>₼ {total.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </>
            )}
        </>
    );
}

export default FakturaC;
