import { useEffect, useState } from 'react';
import axios from 'axios';
import { CONN_KEY } from "../Conn";

function Orders() {
    const [data, setData] = useState([]);
    const [productQuantities, setProductQuantities] = useState({});

    useEffect(() => {
        axios.get(`${CONN_KEY}GetOrder.php`)
            .then(response => {
                const ordersData = response.data.data;
                setData(ordersData); // Store the fetched data

                // Create an object to store total quantities by product name
                const quantities = {};

                // Iterate over all orders and their products
                ordersData.forEach(order => {
                    order.products.forEach(product => {
                        const { product_name, miqdar } = product;
                        const quantity = parseFloat(miqdar); // Convert miqdar to a number

                        // Add to the total quantity for each product_name
                        if (quantities[product_name]) {
                            quantities[product_name] += quantity;
                        } else {
                            quantities[product_name] = quantity;
                        }
                    });
                });

                // Set the calculated quantities in state
                setProductQuantities(quantities);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <div>
            <h2>Product Quantities</h2>
            <ul>
                {Object.entries(productQuantities).map(([productName, totalQuantity]) => (
                    <li key={productName}>
                        <strong>{productName}</strong>: {totalQuantity}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Orders;
