import { Layout, Avatar, Dropdown, theme, Alert, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const { Header } = Layout;

const Headers = () => {
    const handleLogout = () => {
        // Remove the 'username' cookie
        removeCookie('username', { path: '/' });
    };
    const [, , removeCookie] = useCookies(['username']); // Only keep removeCookie

    const items = [
        {
            key: '1',
            label: (
                <Link to={'/Login'} className="logo" onItemClick={handleLogout} style={{ color: '#ED3F3F' }}>
                    Çıxış
                </Link >
            ),
        },
    ];

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <>
            <Header
                style={{
                    padding: '0 ,10px ',
                    textAlign: 'right',
                    background: colorBgContainer,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                {/* <Space>
                    <Alert message="!!! Diqqət bu sadəcə bir demodur !!! " type="warning" closable />
                </Space> */}
                <Dropdown
                    menu={{
                        items,
                    }}
                >
                    <Avatar
                        style={{
                            backgroundColor: '#87d068',
                        }}
                        icon={<UserOutlined />}
                    />
                </Dropdown>

            </Header>
        </>
    );
};

export default Headers;
