import { Card, Button, Modal, Form, Input, Select, Skeleton, Empty, Col, Row, message, Tooltip } from 'antd';
import { PlusOutlined, DeleteOutlined, InfoCircleOutlined, EnvironmentOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CONN_KEY } from "../Conn";

const { Option } = Select;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const CardCustomer = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchData();

        // Refresh data every 1 second
        const interval = setInterval(() => {
            fetchData();
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const fetchData = () => {
        axios.get(`${CONN_KEY}Customer.php`)
            .then(response => {
                setData(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOk = () => {
        form
            .validateFields()
            .then(values => {
                axios.post(`${CONN_KEY}Customer.php`, values)
                    .then(response => {
                        console.log(response.data);
                        setIsModalOpen(false);
                        message.success('Müştəri əlavə edildi!');
                    })
                    .catch(error => {
                        console.error(error);
                        message.error('Müştəri əlavə edilərkən xəta baş verdi!');
                    });
            })
            .catch(error => {
                console.error(error);
                message.error('Zəhmət olmasa, bütün xanaları doldurun!');
            });
    };

    const handleDelete = (id) => {
        axios.delete(`${CONN_KEY}Customer.php?id=${id}`)
            .then(response => {
                console.log(response.data);
                message.success('Müştəri silindi!');
            })
            .catch(error => {
                console.error(error);
                message.error('Müştəri silinərkən xəta baş verdi!');
            });
    };

    return (
        <>
            {isLoading ? (
                <Skeleton />
            ) : data.length > 0 ? (
                <>
                    <Card
                        title="Müşteriler"
                        extra={
                            <Button type="secondary" onClick={showModal} className='ColorMain'>
                                <PlusOutlined /> Əlavə et
                            </Button>
                        }
                    >
                        <Row gutter={[16, 16]}>
                            {data.map(customer => (
                                <Col span={8}>
                                    <Card title={customer.CustomerName} bordered={false}
                                        key={customer.Lokation}
                                        actions={[
                                            <DeleteOutlined key="edit" onClick={() => handleDelete(customer.id)} />,
                                            // <EditOutlined key="edit" onClick={() => showModal2(customer.id)} />
                                        ]}>
                                        <h5 style={{ color: "#767676" }}>Ünvan: </h5>{customer.Lokation}
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card>
                    <Modal title="Müşteri Əlavə et" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Form {...layout} form={form} name="customerForm">
                            <Form.Item
                                name="CustomerName"
                                label="Müştəri Adı"
                                rules={[{ required: true, message: 'Zəhmət olmasa, müştəri adını daxil edin' }]}
                            >
                                <Input placeholder="Müştəri Adı" />
                            </Form.Item>
                            <Form.Item
                                name="Lokation"
                                label="Ünvan"
                            >
                                <Input
                                    placeholder="Müştəri ünvanı qeyd edin"
                                    prefix={<EnvironmentOutlined />}
                                    suffix={<Tooltip title="Müştəri ünvanı qeyd edin"><InfoCircleOutlined /></Tooltip>}
                                />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="Email"
                                // rules={[{ required: true, message: 'Zəhmət olmasa, email daxil edin', type: 'email' }]}
                            >
                                <Input placeholder="Email" prefix={<MailOutlined />} />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label="Telefon"
                                rules={[{ required: true, message: 'Zəhmət olmasa, telefon nömrəsi daxil edin' }]}
                            >
                                <Input placeholder="Telefon" prefix={<PhoneOutlined />} />
                            </Form.Item>
                            <Form.Item
                                name="address"
                                label="Adres"
                            >
                                <Input placeholder="Müştəri adresi" />
                            </Form.Item>
                        </Form>
                    </Modal>

                </>
            ) : (
                <Card
                    title="Müşteriler"
                    extra={
                        <Button type="secondary" onClick={showModal} className='ColorMain'>
                            <PlusOutlined /> Əlavə et
                        </Button>
                    }
                >
                    <Modal title="Müşteri Əlavə et" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <Form {...layout} form={form} name="customerForm">
                            <Form.Item
                                name="CustomerName"
                                label="Müştəri Adı"
                                rules={[{ required: true, message: 'Zəhmət olmasa, müştəri adını daxil edin' }]}
                            >
                                <Input placeholder="Müştəri Adı" />
                            </Form.Item>
                            <Form.Item
                                name="Lokation"
                                label="Ünvan"
                            >
                                <Input
                                    placeholder="Müştəri ünvanı qeyd edin"
                                    prefix={<EnvironmentOutlined />}
                                    suffix={<Tooltip title="Müştəri ünvanı qeyd edin"><InfoCircleOutlined /></Tooltip>}
                                />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[{ required: true, message: 'Zəhmət olmasa, email daxil edin', type: 'email' }]}
                            >
                                <Input placeholder="Email" prefix={<MailOutlined />} />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label="Telefon"
                                rules={[{ required: true, message: 'Zəhmət olmasa, telefon nömrəsi daxil edin' }]}
                            >
                                <Input placeholder="Telefon" prefix={<PhoneOutlined />} />
                            </Form.Item>
                            <Form.Item
                                name="address"
                                label="Adres"
                            >
                                <Input placeholder="Müştəri adresi" />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Empty />
                </Card>
            )
            }
        </>
    );
};
export default CardCustomer;