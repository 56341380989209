import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Tables from "./pages/Tables";
import NotFound from "./components/notfound";
import "./App.css";
import SellingItem from "./pages/Add";
import Order from "./pages/Order";
import Faktura from "./pages/Faktura";
import Main from "./pages/main";
import SIFARISHTable from "./pages/SifariSHler";
import Login from "./pages/Login";
import Customer from "./pages/Customer";
import Settings from "./pages/Settings";
import Calendars from "./pages/Calendar";
import Truck from "./pages/Trucks";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/Main" element={<Main />} />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/Tables" element={<Tables />} />
        <Route exact path="/add" element={<SellingItem />} />
        <Route exact path="/Order" element={<Order />} />
        <Route exact path="/Faktura/:sifarishNo" element={<Faktura />} />
        <Route exact path="/orders" element={<SIFARISHTable />} />
        <Route exact path="/Müşteriler" element={<Customer />} />
        <Route exact path="/Settings" element={<Settings />} />
        <Route exact path="/Kalendar" element={<Calendars />} />
        <Route exact path="/Truck" element={<Truck />} />
        <Route element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
