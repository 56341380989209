import { Card, List, Avatar, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CONN_KEY } from "../Conn";

const CustomersList = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [colorMap, setColorMap] = useState({}); // Object to store customer colors

    useEffect(() => {
        fetchData();

        // Refresh data every 1 second
        const interval = setInterval(() => {
            fetchData();
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const fetchData = () => {
        axios
            .get(`${CONN_KEY}Customer.php`)
            .then(response => {
                setData(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    };
              
    const getLightColor = () => {
        const hue = Math.floor(Math.random() * 360);
        const saturation = Math.floor(Math.random() * 30) + 70; // Lighter saturation (70-100)
        const lightness = Math.floor(Math.random() * 30) + 40; // Lighter lightness (60-80)
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    const getAvatarColor = (customer) => {
        if (colorMap[customer]) {
            return colorMap[customer];
        } else {
            const color = getLightColor();
            setColorMap((prevColorMap) => ({
                ...prevColorMap,
                [customer]: color,
            }));
            return color;
        }
    };
    return (
        <>
            {isLoading ? (
                <Card>
                    <Skeleton active />
                </Card>
            ) : (
                <Card title="Müştərilər"  style={{
                    height: '500px',
                    overflow: 'auto',
                    padding: '0 16px',
                    width: "100%",
                }}>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            style={{
                                                backgroundColor: getAvatarColor(item.CustomerName),
                                                color: '#ffffff',
                                            }}
                                        >
                                            {item.CustomerName.charAt(0)}
                                        </Avatar>
                                    }
                                    title={item.CustomerName}
                                    description={item.Lokation}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            )}
        </>
    );
};

export default CustomersList;
