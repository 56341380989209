import { FallOutlined, RiseOutlined, FileProtectOutlined, ShopOutlined, TeamOutlined, BarcodeOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CONN_KEY } from "../Conn";
import CountUp from 'react-countup';

const App = () => {
    const [stats, setStats] = useState({
        total_products: 0,
        total_customers: 0,
        total_orders: 0,
        total_users: 0,
        total_revenue: 0,
        total_cost: 0,
        total_profit: 0,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchStatistics();
    }, []);

    const fetchStatistics = async () => {
        try {
            const response = await axios.get(`${CONN_KEY}Statistic.php`);
            if (response.data.status === 1) {
                setStats(response.data.data);
            } else {
                console.error("Failed to fetch statistics:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching statistics:", error);
        } finally {
            setLoading(false);
        }
    };

    const dailyRevenue = ((stats.total_revenue || 0) / 30).toFixed(2);
    const monthlyRevenue = (stats.total_revenue || 0).toFixed(2);
    const formatter = (value) => <CountUp end={value} separator="," />;

    return loading ? (
        <div>Loading...</div>
    ) : (
        <Row gutter={[16, 16]}>
            <Col span={4}>
                <Card bordered={false}>
                    <Statistic
                        title="Aylıq Gəlir"
                        value={monthlyRevenue}
                        formatter={formatter}
                        precision={2}
                        valueStyle={{
                            color: stats.total_revenue >= 0 ? '#3f8600' : '#cf1322',
                        }}
                        prefix={stats.total_revenue >= 0 ? <RiseOutlined /> : <FallOutlined />}
                        suffix="₼"
                    />
                </Card>
            </Col>
            <Col span={4}>
                <Card bordered={false}>
                    <Statistic
                        title="Gündəlik gəlir"
                        value={dailyRevenue}
                        formatter={formatter}
                        precision={2}
                        valueStyle={{
                            color: stats.total_revenue >= 0 ? '#3f8600' : '#cf1322',
                        }}
                        prefix={stats.total_revenue >= 0 ? <RiseOutlined /> : <FallOutlined />}
                        suffix="₼"
                    />
                </Card>
            </Col>
            <Col span={4}>
                <Card bordered={false}>
                    <Statistic
                        title="Ümumi Sifariş"
                        value={stats.total_orders}
                        formatter={formatter}
                        precision={0}
                        valueStyle={{
                            color: '#1890ff',
                        }}
                        prefix={<FileProtectOutlined />}
                    />
                </Card>
            </Col>
            <Col span={4}>
                <Card bordered={false}>
                    <Statistic
                        title="İşçilərin sayı"
                        value={stats.total_users}
                        formatter={formatter}
                        precision={0}
                        valueStyle={{
                            color: '#1890ff',
                        }}
                        prefix={<TeamOutlined />}
                    />
                </Card>
            </Col>
            <Col span={4}>
                <Card bordered={false}>
                    <Statistic
                        title="Məhsulların sayı"
                        value={stats.total_products}
                        formatter={formatter}
                        precision={0}
                        valueStyle={{
                            color: '#1890ff',
                        }}
                        prefix={<BarcodeOutlined />}
                    />
                </Card>
            </Col>
            <Col span={4}>
                <Card bordered={false}>
                    <Statistic
                        title="Müştərilərin sayı"
                        value={stats.total_customers}
                        formatter={formatter}
                        precision={0}
                        valueStyle={{
                            color: '#1890ff',
                        }}
                        prefix={<ShopOutlined />}
                    />
                </Card>
            </Col>
        </Row>
    );
};

export default App;
