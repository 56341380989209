import { Layout } from 'antd';
import { CONN_KEY } from "../Conn";
import { VERSİON } from "../Version";
const { Footer } = Layout;
const Footers = () => {

    return (
        <Layout>
            <Footer
                className="login-form-footer"
                
            >
                <span>Asif Design ©2023 Created by Nortaip for </span>
                {/* <span className='ID-USER'>ID: {CONN_KEY}</span>    */}
                <span>VERSİON : {VERSİON}</span>
            </Footer>
        </Layout>
    );
};
export default Footers;