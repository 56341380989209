import { Layout, theme } from 'antd';
import Footers from '../components/Footer';
import Headers from '../components/Header';
import Siders from '../components/Sider';
import Settings from '../components/Settings';
import { useCookies } from 'react-cookie';


const { Content } = Layout;

const Setting = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [cookies] = useCookies(['username']);

    if (!cookies.username) {
        window.location.href = '/Login';
    }

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout"
                    style={{
                        padding: '0 50px',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight: 380,
                            background: colorBgContainer,
                        }}
                    >
                        <Settings />
                    </div>
                </Content>
                <Footers />
            </Layout>
        </Layout>
    );
};
export default Setting;