import { Card} from 'antd';
import PasswordChange from './PassChange';
import GetMgb from './GetMb';
import UsersList from './UsersList';
import QRC from './qr';
import NewUsers from './NewUserAdd';


const Settings = () => (

    <div style={{ display: "flex", gap: 16 }}>
        <div style={{
            width: "100%", gap: 16, display: "grid",
        }}>
            {/* <Card style={{
                width: "100%",
            }}>
                <NewUsers />
            </Card> */}

            <Card style={{
                width: "100%",
            }}>
                <GetMgb />
            </Card>
        </div>
        <div
            style={{
                width: "100%", gap: 16, display: "grid",
            }}>
            {/* <Card style={{
                width: "100%",
            }}>
                <PasswordChange />
            </Card> */}
            {/* <Card style={{
                width: "100%",
            }}>
                <UsersList />
            </Card> */}
            {/* <Card style={{
                width: "100%",
            }}>
                <QRC />
            </Card> */}
        </div>
    </div >
);
export default Settings;