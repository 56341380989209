import React, { useState, useEffect } from 'react';
import { Area } from '@ant-design/plots';
import axios from 'axios';
import { CONN_KEY } from "../Conn";
import { Card } from 'antd';

const DemoLine = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        asyncFetch();
    }, []);

    const asyncFetch = () => {
        axios.get(`${CONN_KEY}Add.php`)
            .then((response) => setData(response.data))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };

    const config = {
        height: 300,
        width: "100%",
        data,
        xField: 'names',
        yField: 'price',
        xAxis: {
            range: [0, 1],
        },
    };

    return <Card><Area {...config} /></Card>;
};

export default DemoLine;
