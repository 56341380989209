import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Layout, message, Image } from 'antd';
import Logo from '../logo192.png';
import axios from 'axios';
import { CONN_KEY } from "../Conn";
import { Link, useNavigate } from 'react-router-dom';  // Import useNavigate
import { VERSİON } from '../Version';

const { Content, Footer } = Layout;

// Helper function to get cookie expiration time
function getCookieExpiration(days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    return date.toUTCString();
}

const Login = () => {
    const navigate = useNavigate();  // Initialize the useNavigate hook

    const onFinish = async (values) => {
        try {
            const response = await axios.post(`${CONN_KEY}login.php`, {
                username: values.username,
                password: values.password,
            });

            if (response.data.status === 1) {
                document.cookie = `username=${values.username}; partitionKey=1235412; expires=${getCookieExpiration(1)}; path=/; Secure; SameSite=none`;

                console.log("Login successful, navigating to /Main");
                navigate('/Main');  // Use navigate to programmatically navigate
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Layout className='bg-login'>
            <Content className='contetn'>
                <div className='login-formloga'>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <Image
                            preview={false}
                            src={Logo}
                            style={{
                                height: '40px',
                            }}
                        />
                        <br />
                        <br />
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Username!',
                                },
                            ]}
                            style={{
                                width: '100%',
                            }}
                        >
                            <Input size='large' prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                            style={{
                                width: '100%',
                            }}
                        >
                            <Input.Password
                                size='large'
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                name='password'
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            <Link className="login-form-forgot" to="">
                                Forgot password
                            </Link>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" block className=" ColorMain login-form-button">
                                Log in
                            </Button>
                        </Form.Item>
                        <p>AD: admin <br /> Password: admin</p>
                    </Form>
                </div>
            </Content>
            <Footer className="login-form-footer">
                <span>Asif Design ©2023 Created by Nortaip for Anbarda <a href='https://anbarda.tech/' style={{ color: "#fff" }} target='_blank'>https://anbarda.tech/</a></span>
                <span>VERSİON : {VERSİON}</span>
            </Footer>
        </Layout>
    );
};

export default Login;
