import { Button, InputNumber, Modal, Space, Table, Tag, Empty, Progress, Tooltip, Skeleton, Badge, Input } from 'antd';
import { Link } from 'react-router-dom';
import { PlusCircleOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { CONN_KEY } from "../Conn.js";
import Highlighter from 'react-highlight-words';

const App = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [stokda, setStokda] = useState(0);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchData = () => {
    axios.get(CONN_KEY + 'Add.php')
      .then(response => {
        const updatedData = response.data.map(item => ({ ...item, key: item.id }));
        setData(updatedData);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const showModal = (record) => {
    setSelectedId(record.id);
    setStokda(record.Stokda);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      if (typeof text === 'undefined') {
        return null;
      }

      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      );
    },
  });

  const columns = [
    {
      title: 'Bar kod',
      dataIndex: 'barkode',
      key: '1',
      ...getColumnSearchProps('barkode'),
    },
    {
      title: 'Məhsulun adı',
      dataIndex: 'names',
      key: '2',
      ...getColumnSearchProps('names'),
    },
    {
      title: 'Ölcüsü',
      dataIndex: 'size',
      key: '3',
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
    },
    {
      title: 'Qiyməti',
      dataIndex: 'price',
      key: '4',
      with: 100,
      sorter: {
        compare: (a, b) => a.price - b.price,
        multiple: 4,
      },
      render: (value) => `${value} ₼`,
    },
    {
      title: 'Satış',
      dataIndex: 'miqdar', // Amount added to stock
      key: '5',
      render: (value, record) => {
        // Calculate percentage of stock sold
        const yuzde_sold = ((value - record.Stokda) / value) * 100;
        return (
          <Tooltip title="Satılan malın faizi">
            <Progress percent={yuzde_sold.toFixed(2)} size="small" />
          </Tooltip>
        );
      },
    },
    {
      title: 'Əlavə məlumat',
      dataIndex: 'description',
    },
    {
      title: 'Hazırkı sayı', // Current stock after sales
      dataIndex: 'Stokda',
      key: '6',
      sorter: {
        compare: (a, b) => a.Stokda - b.Stokda,
        multiple: 1,
      },
    },
    {
      title: 'Status',
      dataIndex: 'miqdar', // Total added stock
      key: '7',
      sorter: {
        compare: (a, b) => a.miqdar - b.miqdar,
        multiple: 5,
      },
      render: (value, record) => {
        const kalan = record.Stokda; // Remaining stock
        const yuzde_sold = ((value - kalan) / value) * 100; // Sold percentage

        if (kalan <= 0) {
          return <Tag color="red"><Badge status="processing" color='#f5222d' /> Bitib</Tag>; // Stock depleted
        } else if (yuzde_sold >= 95) {
          return <Tag color="orange">{`${yuzde_sold.toFixed(2)}% - Bitmək üzərədir`}</Tag>; // Stock almost depleted
        } else {
          return <Tag color="green">{`${yuzde_sold.toFixed(2)}% - Satılıb, ${kalan} qalır`}</Tag>; // Stock remaining
        }
      },
    },
    {
      title: 'Tarix',
      dataIndex: 'created_at',
      key: '8',
      sorter: {
        compare: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        multiple: 1,
      },
    },
    {
      title: 'Action',
      key: '9',
      render: (value, record) => (
        <Space>
          <Button className='ColorMain' onClick={() => showModal(record)} icon={<PlusCircleOutlined />} />
          <Button
            danger
            onClick={(e) => {
              e.stopPropagation();
              Modal.confirm({
                title: "Are you sure you want to delete this item?",
                onOk: () => {
                  axios
                    .delete(CONN_KEY + `DeletPro.php?id=${record.id}`)
                    .then(() => {
                      Modal.success({
                        content: "The item has been deleted successfully.",
                      });
                      handleCancel();
                    });
                },
              });
            }}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    }
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : data.length > 0 ? (
        <>
          <Table
            columns={columns}
            dataSource={data}
            onChange={onChange}
          />
          <Modal title="Stoku yenile" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <InputNumber
              placeholder={stokda}
              color="#5B5B5B"
              min={1}
              onBlur={(event) => {
                if (event.target.value) {
                  Modal.confirm({
                    title: 'Verileri güncellemek istediğinize emin misiniz?',
                    onOk: () => {
                      axios
                        .post(`${CONN_KEY}StockUpdate.php?id=${selectedId}`, {
                          id: selectedId,
                          Stokda: event.target.value,
                        })
                        .then(() => {
                          axios.get(`${CONN_KEY}StockUpdate.php`).then((response) => {
                            setData(response.data);
                          });
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                      Modal.success({
                        content: 'Veriler başarılı bir şekilde güncellendi.',
                        onOk: () => {
                          window.location.reload(); // Refresh the page after successful update
                        },
                      });
                      handleCancel();
                    },
                  });
                }
              }}
            />
          </Modal>
        </>
      ) : (
        <div>
          <p>No data found.</p>
          <Empty />
          <Button type="primary" className='ColorMain'>
            <Link to="/add">Add</Link>
          </Button>
        </div>
      )}
    </>
  );
};

export default App;
